import React, { useEffect, useState } from "react";
import { HStack, VStack } from "@chakra-ui/react";
import imageService from "../service/imageService";
import { Buffer } from "buffer";

import fondo from "../assets/wave2.svg";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import diagnostico from "../assets/diagnostico.png";

const Procesada = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (!location.state.processed) {
      let response = await imageService.postProcessImage(location.state.image);
      console.log("🚀 ~ file: Procesada.jsx:24 ~ getData ~ response", response.data)
      let id_image = response.data.id;
      setTimeout(
        async () => {
          let response = await imageService.getImageProcessed(id_image);
          let base64ImageString = Buffer.from(response, "binary").toString(
            "base64"
          );
          setImage(`data:image/jpeg;base64,${base64ImageString}`);
          setIsLoading(false);
        },
        1000,
        this
      );
    } else {
      setImage(location.state.image);
      setIsLoading(false);
    }
  };

  const toInicio = () => {
    navigate("/");
  };

  const toNegatoscopio = () => {
    navigate("/negatoscope", {
      state: { image: image, processed: true },
    });
  };

  return (
    <VStack
      background={`url(${fondo})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      justify={"center"}
      h="100%"
      w="100%"
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
          <label style={{ marginTop: "1%" }}>
            Procesando imagen, por favor espere un momento...
          </label>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width:"100%",
            height:"100%"
          }}
        >
          <h1>Diagnostico Dental</h1>
          <img alt="not fount" height={"75%"} src={image} />

          <HStack
            marginBottom="20px"
            paddingLeft="40px"
            paddingRight="40px"
            paddingTop="30px"
            w="100%"
            justify="space-between"
          >
            <button
              style={{ borderColor: "#59C4FF", border: "1px solid #3992FF" }}
              onClick={() => toInicio()}
            >
              Volver
            </button>
            <button
              style={{ borderColor: "#59C4FF", border: "1px solid #3992FF" }}
              onClick={() => toNegatoscopio()}
            >
              Ver en negatoscopio
            </button>

            <a href={diagnostico} target="_blank" download="Diagnostico.jpg">
              <button
                href={diagnostico}
                style={{ borderColor: "#59C4FF", border: "1px solid #3992FF" }}
                onClick={() => console.log("Descargar PDF")}
              >
                Descargar Diagnóstico
              </button>
            </a>
          </HStack>
        </div>
      )}
    </VStack>
  );
};

export default Procesada;
