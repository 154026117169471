import React, { Suspense, useState, useEffect } from "react";
import { motion } from 'framer-motion';
import axios from "axios";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { TextureLoader, ShaderMaterial } from 'three';
import { IoMdDownload } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import forma1 from "../assets/forma1.png";
import forma2 from "../assets/forma2.png";
import fotoProcesada from "../assets/pantoejemplo2.jpg";
import dowloadSvg from "../assets/dowload.svg";
import LoadingSpinner from "./LoadingSpinner";
import { saveAs } from "file-saver";
import example from "../assets/example.pdf";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Text,
  Heading,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Flex,
  Center
} from "@chakra-ui/react";


const MyNegatoscopio = () => {
  // React // -----------------------------
  const location = useLocation();
  const navigate = useNavigate();
  // ----------------------------------------

  // Variables archivos // -----------------------------
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  // ----------------------------------------

  // Buttons y Spinners // -----------------------------
  const [closeButton, setCloseButton] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);
  // ----------------------------------------

  // Variables para los sliders // -----------------------------
  const [showSlider, setShowSlider] = useState(false);
  const [valueSepia, setValueSepia] = React.useState(0);
  const [valueGrises, setValueGrises] = React.useState(0);
  const [valueInvertido, setValueInvertido] = React.useState(0);
  const [valueHue, setValueHue] = React.useState(0);
  const [radioSup, setRadioSup] = React.useState(2);
  const [radioInf, setRadioInf] = React.useState(2);
  const [opacity1, setOpacity1] = useState(1);
  const [opacity2, setOpacity2] = useState(0);
  const [isSliderLocked, setIsSliderLocked] = useState(true);

  // ----------------------------------------

  // Images y diagnostico // -----------------------------
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [diag, setDiag] = useState(null);
  const [imageStock, setImageStock] = useState(null);
  // ----------------------------------------

  // Modals // -----------------------------
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();

  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();
  // ----------------------------------------

  // Handles para los sliders // -----------------------------
  const handleChange = (newValue) => {
    setValueSepia(newValue);
  };

  const handleGrises = (newValue) => {
    setValueGrises(newValue);
  };

  const handleInvertido = (newValue) => {
    setValueInvertido(newValue);
  };

  const handleHue = (newValue) => {
    setValueHue(newValue);
  };

  const handleSup = (newValue) => {
    setRadioSup(newValue);
  };

  const handleInf = (newValue) => {
    setRadioInf(newValue);
  };


  const handleOpacityChange = (newValue) => {
    setOpacity1(newValue);
    setOpacity2(newValue);
  };
  // ----------------------------------------

  // Navigate // -----------------------------
  const goBack = () => {
    navigate("/");
  };
  // ----------------------------------------

  // Funciones de carga spinner // -----------------------------
  const timerSpinner = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setDiag(1);
      setShowSpinner(false);
    }, 3000);
  };

  // ----------------------------------------

  // Funcion para marcar la imagen como cargada
  const handleImageLoad = () => {
    setIsImageLoaded(true);
    setIsSliderLocked(false);
  };

  // Funciones de files // -----------------------------
  const descargarpdf = () => {
    saveAs(example, "example.pdf");
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  // Fijarse de hacer modular
  const handleProcesarClick = () => {
    const baseUrl = 'https://testing.panto.jmbajo.com/';
      if (selectedFile) {
        const formData = new FormData();
        formData.append('imagen', selectedFile);
        console.log(selectedFile);
        const requestOptions = {
          method: 'POST',
          body: formData,
        };

        fetch('https://testing.panto.jmbajo.com/process_panto', requestOptions)
          .then(response => response.json())
          .then(data => {
            console.log(data.img_path);
            const relativePath = data.img_path;
            const fullUrl =`${baseUrl}${relativePath}`;
            console.log('URL completa de la imagen procesada:', fullUrl);
            const mensaje = data.mensaje;
            setSelectedImage([...selectedImage, fullUrl]);
          })
          .catch(error => {
            console.error('Error al procesar la solicitud:', error);
          });
      } else {
        console.error('No se ha seleccionado ningún archivo');
      }
};

  const handleChangeFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile (file);
    console.log(file);
    setSelectedImage([...selectedImage, URL.createObjectURL(event.target.files[0])]);
    setIsImageLoaded(false);
  };
  // ----------------------------------------

  // Funcion de cilindro (Mapeo de imagen y se agrega al texture) // -----------------------------
  const shaderMaterial = new ShaderMaterial({
    uniforms: {
      texture1: { value: new TextureLoader().load(selectedImage[0]) },
      texture2: { value: new TextureLoader().load(selectedImage[1]) },
      opacity1: { value: opacity1 }, // Opacidad de la primera imagen
      opacity2: { value: opacity2 }  // Opacidad de la segunda imagen
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D texture1;
      uniform sampler2D texture2;
      uniform float opacity1; // Opacidad de la primera imagen
      uniform float opacity2; // Opacidad de la segunda imagen
      varying vec2 vUv;
      void main() {
        vec4 color1 = texture2D(texture1, vUv);
        vec4 color2 = texture2D(texture2, vUv);
        gl_FragColor = mix(color1, color2, opacity1 * opacity2); // Aplicar opacidad
      }
    `
  });

  function Cilindro() {
    const [textures, setTextures] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const loader = new TextureLoader();

      const loadTexturesSync = async () => {
        const loadedTextures = [];
        for (const image of selectedImage) {
          const texture = await loader.loadAsync(image);
          loadedTextures.push(texture);
        }
        setTextures(loadedTextures);
        setLoading(false);
      };

      loadTexturesSync();
    }, []);

    return (
      <mesh scale={[1.05, 1.2, 1.05]}>
        <cylinderGeometry args={[radioSup, radioInf, 3, 100, 1, true, 4.4, 3.8]} />
        <shaderMaterial attach="material" args={[shaderMaterial]} />
      </mesh>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        backgroundImage: `url(${forma1}), url(${forma2})`,
        backgroundPosition: "right top, right 60px top ",
        backgroundSize: "100% 105%",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/* SPINNER */}
      {showSpinner && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '999',
          }}
        >
          <LoadingSpinner />
        </div>
      )}

      <Box
          display={"flex"}
           w={"33.33%"}
          flexDirection={"column"}
          alignItems={"center"}
          fontFamily="primaryFont"
          fontSize="36px"
          color="#03249B"
          fontWeight="1000"
          m={"30px"}
        >
          <Text m={"30px"} mt={"40px"}>
            Negatoscopio <span style={{ fontWeight: 400 }}>digital</span>
          </Text>
          {selectedImage.length > 0 && (
          <Box>
            <img
              alt="not found"
              style={{
                borderRadius: "9px",
                height: "160px",
                width: "410px"
              }}
              src={selectedImage[0]}
              onLoad={handleImageLoad}
            />
            <br />
          </Box>
          )}
            <Box
              width="400px"
              height="160px"
              display={selectedImage.length > 0 ? "none" : "flex"}
              flexDirection="column"
              background="rgba(255, 255, 255, 0.8)"
              padding="20px"
              backgroundColor="#D4E1E5"
              borderRadius="10px"
            >
              <Box>
                <Box display={"flex"} flexDirection={"row"}>
                  <Image
                    boxSize="40px"
                    m="10px"
                    src={dowloadSvg}
                    alt="dowloadIcon"
                  />
                  <Heading
                    textAlign="center"
                    fontWeight="400"
                    fontSize="x-large"
                    fontFamily="secondaryFont"
                  >
                    <span style={{ fontWeight: 600 }}>Subir</span> la imagen desde
                    una carpeta
                  </Heading>
                </Box>
              </Box>
              <Box m={"20px"} ml={"50px"}>
                <Button
                  onClick={handleClick}
                  variant="outline"
                  background="secondaryColor"
                  color="black"
                  border="none"
                  right="0%"
                  bottom="20%"
                  height="48px"
                  width="260px"
                  borderRadius="30px"
                  fontSize="24px"
                  _hover="gray"
                  boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.10)"
                >
                  Seleccionar
                </Button>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                  onChange={handleChangeFile}
                  accept=".jpg, .png, .jpeg, image/"
                />
              </Box>
            </Box>
            {/* Button volver */}
            <Box
              position="absolute"
              left="120px"
              bottom="100px"
              p="20px"
            >
              <Button
                _hover="none"
                background="azulClaroBackground"
                color="black"
                height="48px"
                width="260px"
                borderRadius="30px"
                fontSize="24px"
                onClick={onOpenModal1}
              >
                Volver
              </Button>
            </Box>
            {/* Button procesar */}
            {selectedImage.length > 0 && closeButton && (
              <Box
                position="absolute"
                right="120px"
                bottom="110px"
                p="20px"
              >
                <Button
                  _hover="none"
                  background="secondaryColor"
                  color="black"
                  height="48px"
                  width="260px"
                  borderRadius="30px"
                  fontSize={"24px"}
                  onClick={() => {
                    timerSpinner();
                    handleProcesarClick ();
                    setCloseButton(false);
                  }}
                >
                  Procesar
                </Button>
            </Box>
            )}
            {/* Button diagnosticar nuevamente */}
            {diag && (
                    <Box
                      position="absolute"
                      right="120px"
                      bottom="110px"
                      p="20px"
                    >
                      <Button
                        _hover="none"
                        background="secondaryColor"
                        color="black"
                        height="48px"
                        width="300px"
                        borderRadius="30px"
                        fontSize={"24px"}
                        onClick={() => window.location.reload()}
                      >
                        Diagnosticar nuevamente
                      </Button>
                    </Box>
            )}
            {/* Pop Up Descarga */}
            <Modal
              closeOnOverlayClick={true}
              isCentered
              isOpen={isOpenModal1}
              onClose={onCloseModal1}
            >
              <ModalOverlay />
              <ModalContent
                borderRadius={"100px"}
                maxH={"280px"}
                maxW={"860px"}
                p={"30px"}
                marginTop="30vh"
                fontFamily="secondaryFont"
                fontSize={"24px"}
              >
                <ModalHeader
                  display="flex"
                  justifyContent="center"
                  mt={"10px"}
                  fontWeight={"400"}
                  fontSize={"24px"}
                  mb={"10px"}
                >
                  ¿Está seguro de salir?
                </ModalHeader>
                <ModalFooter display="flex" justifyContent={"center"}>
                  <Button
                    onClick={() => goBack()}
                    m={"10px"}
                    background="azulClaroBackground"
                    color="black"
                    height="48px"
                    width="260px"
                    bottom="10%"
                    borderRadius="30px"
                    fontSize={"24px"}
                    _hover="none"
                  >
                    Volver
                  </Button>
                  <Button
                    mr={3}
                    m={"10px"}
                    background="secondaryColor"
                    color="black"
                    height="48px"
                    width="260px"
                    bottom="10%"
                    borderRadius="30px"
                    fontSize={"24px"}
                    _hover="none"
                    onClick={onCloseModal1}
                  >
                    Continuar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          {diag && (
            <motion.div
            style={{ width :420 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            >
              <Heading fontSize={"large"} fontWeight={"500"} h={"200px"}>
                Diagnostico, denticion permanente incompleta imagen lucida con
                perdida de sustancias etc...
              </Heading>
              {/* Input, carga de imagenes */}
              <Box>
                <Button
                  _hover="none"
                  marginTop="10%"
                  height="48px"
                  w={"420px"}
                  backgroundColor="#03249B"
                  color="white"
                  fontSize={"20px"}
                  borderRadius="30px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={onOpenModal2}
                >
                  <IoMdDownload mr={"5px"} />
                  Descargar diagnostico
                </Button>
                {/* Pop Up Descarga */}
                <Modal
                  closeOnOverlayClick={true}
                  isCentered
                  isOpen={isOpenModal2}
                  onClose={onCloseModal2}
                >
                  <ModalOverlay />
                  <ModalContent
                    borderRadius={"100px"}
                    maxH={"280px"}
                    maxW={"860px"}
                    p={"30px"}
                    marginTop="30vh"
                    fontFamily="secondaryFont"
                    fontSize={"24px"}
                  >
                    <ModalHeader
                      display="flex"
                      justifyContent="center"
                      mt={"10px"}
                      fontWeight={"400"}
                      fontSize={"24px"}
                      mb={"10px"}
                    >
                      ¿Desea descargar el diagnóstico?
                    </ModalHeader>
                    <ModalFooter display="flex" justifyContent={"center"}>
                      <Button
                        _hover="none"
                        onClick={onCloseModal2}
                        m={"10px"}
                        background="azulClaroBackground"
                        color="black"
                        height="48px"
                        width="260px"
                        bottom="10%"
                        borderRadius="30px"
                        fontSize={"24px"}
                      >
                        Volver
                      </Button>
                      <Button
                        _hover="none"
                        mr={3}
                        m={"10px"}
                        background="secondaryColor"
                        color="black"
                        height="48px"
                        width="260px"
                        bottom="10%"
                        borderRadius="30px"
                        fontSize={"24px"}
                        onClick={() => {
                          descargarpdf();
                          onCloseModal2();
                        }}
                      >
                        Descargar
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>
            </motion.div>
          )}
        </Box>
        {/* ---------------------------------------------------------------------------------------------------------------- */}
        {selectedImage.length > 0 && (
            <div
              style={{
                  height: "90vh",
                  width: "33.33%",
              }}
            >
            <Canvas style={{filter: `sepia(${valueSepia}%) grayscale(${valueGrises}%) invert(${valueInvertido}%) hue-rotate(${valueHue}deg)`,
            }}>
                <Suspense fallback={null}>
                  <ambientLight intensity={0.5} />
                  <spotLight angle={0.15} penumbra={1} position={[10, 10, 10]} />
                  <pointLight position={[-10, -10, -10]} />
                  <Cilindro
                    position={[0, 0, 0]}
                    radioInf={radioInf}
                    radioSup={ radioSup}
                    rotation={[0, -1.5, 0]}
                  />
                </Suspense>
                <OrbitControls
                  maxAzimuthAngle={1.5}
                  maxPolarAngle={0}
                  minAzimuthAngle={-1.5}
                  minPolarAngle={1.6}
                  enableZoom={false}
                />
              </Canvas>
              <Text fontSize="24px" color="gray.500" textAlign={"center"} fontFamily={'secondaryFont'} mb={"110px"}>
                Utilice el <Text as="span" fontWeight="bold">cursor</Text> para <Text as="span" fontWeight="bold">rotar</Text> el negatoscopio
              </Text>
            </div>
          )}
    {/* ---------------------------------------------------------------------------------------------------------------- */}
    <Box mt={"4%"} w={"33.33%"} p={"62px"} ml={"20px"} position={!isImageLoaded ? "absolute" : "static"} right={!isImageLoaded ? "0" : "auto"} top={!isImageLoaded ? "0" : "auto"}>
        <Box fontSize={"20px"} fontFamily={"secondaryFont"} color={"black"}>
          <Box>
            <Text>Superior</Text>
            <Slider
              aria-label="Volume"
              defaultValue={1}
              max={2.5}
              min={isSliderLocked ? 2 : 1.7}
              step={0.00001}
              value={radioSup}
              onChange={(newValue) => handleSup(newValue)}
              isDisabled={!isImageLoaded}
              mb={"20px"}
            >
              <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                <SliderFilledTrack
                  bg={"azulClaroBackground"}
                  boxShadow={"6px"}
                />
              </SliderTrack>
              <SliderThumb
              bg={"azulOscuro"}
              borderRadius="full"
              w="10px"
              h="24px"
              />
            </Slider>
          </Box>
          <Box>
            <Text>Inferior</Text>
            <Slider
              aria-label="Volume"
              max={2.5}
              min={isSliderLocked ? 2 : 1.7}
              step={0.00001}
              value={radioInf}
              onChange={handleInf}
              isDisabled={!isImageLoaded}
              mb={"20px"}
            >
              <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                <SliderFilledTrack
                  bg={"azulClaroBackground"}
                  boxShadow={"6px"}
                />
              </SliderTrack>
              <SliderThumb
              bg={"azulOscuro"}
              borderRadius="full"
              w="10px"
              h="24px"
              />
            </Slider>
          </Box>
          <Box>
            <Text>Sepia</Text>
            <Slider
              value={valueSepia}
              onChange={handleChange}
              isDisabled={!isImageLoaded}
              mb={"20px"}
            >
              <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                <SliderFilledTrack
                  bg={"azulClaroBackground"}
                  boxShadow={"6px"}
                />
              </SliderTrack>
              <SliderThumb
              bg={"azulOscuro"}
              borderRadius="full"
              w="10px"
              h="24px"
              />
            </Slider>
          </Box>
          <Box>
            <Text>Grises</Text>
            <Slider
              aria-label="Volume"
              value={valueGrises}
              onChange={handleGrises}
              isDisabled={!isImageLoaded}
              mb={"20px"}
            >
              <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                <SliderFilledTrack
                  bg={"azulClaroBackground"}
                  boxShadow={"6px"}
                />
              </SliderTrack>
              <SliderThumb
              bg={"azulOscuro"}
              borderRadius="full"
              w="10px"
              h="24px"
              />
            </Slider>
          </Box>
          <Box>
            <Text>Invertido</Text>
            <Slider
              aria-label="Volume"
              value={valueInvertido}
              onChange={handleInvertido}
              isDisabled={!isImageLoaded}
              mb={"20px"}
            >
              <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                <SliderFilledTrack
                  bg={"azulClaroBackground"}
                  boxShadow={"6px"}
                />
              </SliderTrack>
              <SliderThumb
              bg={"azulOscuro"}
              borderRadius="full"
              w="10px"
              h="24px"
              />
            </Slider>
          </Box>
          <Box>
            <Text>Color</Text>
            <Slider
              aria-label="Volume"
              max={360}
              value={valueHue}
              onChange={handleHue}
              isDisabled={!isImageLoaded}
            >
              <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                <SliderFilledTrack
                  bg={"azulClaroBackground"}
                  boxShadow={"6px"}
                />
              </SliderTrack>
              <SliderThumb
              bg={"azulOscuro"}
              borderRadius="full"
              w="10px"
              h="24px"
              />
            </Slider>
          </Box>
          {diag && (
              <motion.div
              style={{ marginTop :40 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              >
              <Text>Visualización del diagnostico</Text>
              <Slider
                aria-label="Opacity"
                defaultValue={0.5}
                max={1}
                min={0}
                step={0.01}
                value={opacity2}
                onChange={handleOpacityChange}
                isDisabled={!isImageLoaded}
              >
                <SliderTrack bg={"blancoIntermedio"} height="10px" borderRadius="full">
                  <SliderFilledTrack
                    bg={"secondaryColor"}
                    boxShadow={"6px"}
                  />
                </SliderTrack>
                <SliderThumb
                bg={"secondaryColor"}
                borderRadius="full"
                w="10px"
                h="24px"
                />
              </Slider>
            </motion.div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MyNegatoscopio;
