import React from "react";
import imageHome from "../assets/Home.png";
import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
  Button,
  Card,
  CardBody,
  CardHeader,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import forma1 from '../assets/forma1.png';
import forma2 from '../assets/forma2.png';
import celular from '../assets/mobile.svg';
import mail from '../assets/tabler_mail.svg';
import logoFooter from '../assets/LogoFooter.png';
import linkPag from '../assets/link.svg';
import logoMuela from '../assets/logo_muelita.svg';
import muelaLupa from '../assets/muelitaLupa.svg';
import muelaAnalisis from '../assets/muelaAnalisis.svg';
import dientes from '../assets/dientes.svg';

export default function Home() {
  const navigate = useNavigate();

  /* Conexion react-rooterdom */
  const toMenu = () => {
    navigate("/negatoscope");
  };

  return (
    <Box
    css={{
      backgroundImage: `url(${forma1}), url(${forma2})`,
      backgroundPosition: 'right top, right 60px top ',
      backgroundSize: '100% 105%',
      backgroundRepeat: 'no-repeat',
    }}
    >
    <Flex
    flexDirection={"column"}
    >
      <Flex
        fontSize="32px"
        fontFamily="primaryFont"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        p={"40px"}
      >
        {/* Primera box, texto */}
        <Box mr={"90px"}>
          <Box
            display="flex"
            mb={"30px"}
            mt={"20px"}
            alignItems="center"
            justifyContent="center"
          >
            {/* Aca iria el icono del programa */}
            <Box mb={"40px"}>
            <Box display="flex" justifyContent="center" pb={"6"}>
              <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 1, type: 'spring', stiffness: 120 }}
              mr="90px"
              >
                <Image
                  boxSize="110px"
                  src={logoMuela}
                  alt="Icon"
                />
              </motion.div>
            </Box>
              <Heading
              fontSize="36px"
              fontWeight="500"
              lineHeight="48px"
              fontFamily="primaryFont"
              textAlign="center"
              w="410px"
              color="headingAzul"
            >
              <motion.div
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ duration: 1, type: 'spring', stiffness: 120 }}
              mr="90px"
              >
                Diagnóstico remoto de<br />
                radiografías panorámicas
              </motion.div>
            </Heading>
            </Box>
          </Box>
          <Box
            color="#000"
            textAlign="justify"
            fontFamily="secondaryFont"
            fontSize="24px"
            fontWeight="400"
            lineHeight="48px"
            width="410px"
            height="530px"
            pl={"30px"}
          >
            <motion.div
            initial={{ x: 1000 }} // Aparece desde la derecha
            animate={{ x: 0 }}
            transition={{ duration: 1, type: 'spring', stiffness: 120 }}>
              <Text marginBottom="10px" pt={"10px"} textAlign={"center"} color="headingAzul" fontFamily="primaryFont" fontSize={"28px"} fontStyle={"italic"} >Transformando la odontología</Text>
              <Text marginBottom="20px">
              Diagnóstico preciso y rápido a través de un análisis inteligente y remoto de radiografías panorá-micas.
              </Text>
            <UnorderedList marginBottom="40px" listStyleType="disc" paddingLeft={8}>
              <ListItem>Asiste a los profesionales de la odontología en la toma de decisiones clínicas.</ListItem>
            </UnorderedList>
            </motion.div>
          </Box>

        </Box> {/* Aca termina la primera box */}

        {/* Segunda box, imagen */}
        <Box w="900px" h="900px"  boxSize="42rem" mb="100px">
          <motion.div
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          transition={{ duration: 1, type: 'spring', stiffness: 120 }}
          mr="90px"
          >
            <Image src={imageHome} alt="panto" borderRadius="md" />
          </motion.div>
        </Box>

        {/* Tercera box, texto y boton */}
          <motion.div
                  initial={{ x: -1000 }} // Aparece desde la izquierda
                  animate={{ x: 0 }}
                  transition={{ duration: 1, type: 'spring', stiffness: 120 }}>
          <Box  mr={"80px"}  ml={"80px"}>
            <Box
              color="#000"
              textAlign="justify"
              fontFamily="secondaryFont"
              fontSize="24px"
              fontWeight="400"
              lineHeight="48px"
              width="410px"
              height="530px"
            >
              <UnorderedList marginBottom="40px" listStyleType="disc" >
                <ListItem>Analiza una radiografía panorámica y brinda un diagnóstico asistido y automatizado para la detección de condiciones y patologías dentales.</ListItem>
                <ListItem>Detecta y segmenta automática-mente piezas dentales, cuantifi-cándolas y describiendo las condiciones diagnósticas en len-guaje natural.</ListItem>
                <ListItem>Velocidad y eficiencia en la detección temprana de problemas dentales.</ListItem>
              </UnorderedList>
            </Box>
            <Box display="flex" justifyContent="right" mt={"100px"} pb={"50px"}>
              <Button
                onClick={() => toMenu()}
                fontSize={"20px"}
                borderRadius= {"100px"}
                boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.10)"}
                fontFamily={"secondaryFont"}
                color={"black"}
                backgroundColor={"secondaryColor"}
                _hover={{ backgroundColor: "none" }}
              >
                <Text pl={"40px"} pr={"40px"}>
                  Diagnosticar
                </Text>
              </Button>
            </Box>
          </Box>
          </motion.div>
      </Flex>

{/* Grupo Cards */}
    <motion.div
            initial={{ y: 1000 }} // Aparece desde abajo
            animate={{ y: 0 }}
            transition={{ duration: 1, type: 'spring', stiffness: 120 }}>
      <Box display={"flex"} flexDirection={"column"} >
        <SimpleGrid columns={3} spacing={10} h={"800px"} p={"40px"} pt={"30px"} mb={"30px"}>

          <Card transform="translateY(-70px)" background={"azulClaro"} borderRadius={"100px"} boxShadow="0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)">
          <CardHeader display="flex" justifyContent="center" alignItems="center" mt={"20px"}>
          <Heading
            height="46px"
            color="black"
            fontFamily="primaryFont"
            fontSize="34px"
            fontWeight="700"
            lineHeight="48px"
            pt={"20px"}
          >
            Nuestro Objetivo
          </Heading>
          </CardHeader>
          <CardBody>
            <Text
              color="black"
              fontFamily="secondaryFont"
              fontSize="24px"
              lineHeight="48px"
              p="16px"
              textAlign="justify"
            >
              Buscamos establecer un producto y servicio que brinden <span style={{ fontWeight: 600 }}>diagnósticos presuntivos precisos y asequibles,</span> siendo atractivo para profesionales dentales.
              <Box mt={"100px"} display={"flex"} justifyContent={"center"}>
                <Image src={muelaAnalisis} alt="panto" borderRadius="md" pr={"18px"}/>
              </Box>
            </Text>
          </CardBody>
          </Card>


          <Card transform="translateY( 40px)"background={"blancoIntermedio"} borderRadius={"100px"} boxShadow="0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)">
            <CardHeader display="flex" justifyContent="center" alignItems="center" mt={"20px"}>
            <Heading
              height="46px"
              color="black"
              fontFamily="primaryFont"
              fontSize="34px"
              fontWeight="700"
              lineHeight="48px"
              pt={"20px"}
            >
              Negatoscopio digital
            </Heading>
            </CardHeader>
            <CardBody>
              <Text
                color="black"
                fontFamily="secondaryFont"
                fontSize="24px"
                lineHeight="48px"
                p="16px"
                textAlign="justify"
              >
                De servicio online, donde las personas usuarias pueden cargar su radiografía panorámica en formato digital y obtener la <span style={{ fontWeight: 600 }}> visualización del negatoscopio digital junto al diagnóstico</span> presuntivo asociado.
              </Text>
              <Box mt={"60px"} display={"flex"} justifyContent={"center"}>
                <Image src={muelaLupa} alt="panto" borderRadius="md" pt={"18px"} pl={"40px"}/>
              </Box>
            </CardBody>
          </Card>
          <Card transform="translateY(-70px)" background={"azulClaro"} borderRadius={"100px"} boxShadow="0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)" pt={"20px"}>
            <CardHeader display="flex" justifyContent="center" alignItems="center" mt={"20px"}>
              <Heading
                height="46px"
                color="black"
                fontFamily="primaryFont"
                fontSize="34px"
                fontWeight="700"
                lineHeight="48px"
              >
                Modelo 3D
            </Heading>
            </CardHeader>
            <CardBody>
              <Text
                  color="black"
                  fontFamily="secondaryFont"
                  fontSize="24px"
                  lineHeight="48px"
                  p="16px"
                  textAlign="justify"
                >
                  La radiografía se proyecta sobre un modelo digital 3D, aproximado al macizo craneofacial, facilitando la inspección de las detecciones mencionadas de una forma conveniente. Permite <span style={{ fontWeight: 600 }}>ampliar, rotar y ajustar el contraste de las imágenes</span> para un mejor análisis visual.
              </Text>
              <Box mt={"60px"} display={"flex"} justifyContent={"center"} h={"120px"} w={"140"}>
                <Image src={dientes} alt="panto" borderRadius="md" pr={"18px"} pl={"40px"}/>
              </Box>
            </CardBody>
          </Card>
        </SimpleGrid>
      </Box>
    </motion.div>

    {/* Footer */}
    <Box
      as="footer"
      backgroundColor="gray.200"
      width="100%"
      height="13rem"
      borderTopRadius={"100px"}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      p={"60px"}
      mt={"80px"}
      fontFamily="secondaryFont"
      fontWeight={400}
      fontSize={"24px"}
    >
        <Box>
          <Image src={logoFooter} alt="panto" borderRadius="md" />
        </Box>

        <Box mt={"20px"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Image src={linkPag} alt="panto" borderRadius="md" pr={"18px"}/>
            <Text>www.diagnosticodental.com.ar</Text>
          </Box>
        </Box>

        <Box mt={"20px"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Image src={mail} alt="panto" borderRadius="md" pr={"18px"} />
            <Text>info@diagnosticodental.com.ar</Text>
          </Box>
        </Box>

        <Box mt={"20px"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Image src={celular} alt="panto" borderRadius="md" pr={"18px"}/>
            <Text>+54 291 442 8645</Text>
          </Box>
        </Box>
      </Box>
    </Flex>

    </Box>
  );
}
