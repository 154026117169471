import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import ErrorPage from "./error-page";
import Home from "./routes/home";
import MyNegatoscopio from "./routes/myNegatoscopio";
import Menu from "./routes/menu";
import ConfigAxios from "./middleware/axiosConfig";
import Procesada from "./routes/Procesada";

const theme = extendTheme({
  fonts: {
    primaryFont: "Spectral",
    secondaryFont: "Prompt Light, sans-serif  ",
  },
  colors: {
    mainColor: "rgba(0, 0, 0, 0.8)",
    mainLight: "rgba(0, 0, 0, 0.36)",
    primaryColor: "rgba(3, 36, 155, 1)",
    secondaryColor: "rgba(64, 255, 163, 1)",
    headingAzul: "rgba(3, 36, 155, 1)",
    azulClaro: "rgba(212, 225, 229, 1)",
    blancoIntermedio: "rgba(242, 247, 249, 1)",
    azulClaroBackground: "rgba(184, 209, 240, 1)",
    azulOscuro: "rgba(66, 118, 180, 0.7)",
  },
  styles: {
    global: {
      body: {
        bg: "rgba(184, 209, 240, 1)",
      },
    },
  },
});
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/selectImage",
    element: <Menu />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/negatoscope",
    element: <MyNegatoscopio />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/process",
    element: <Procesada />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ConfigAxios />
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
);
