import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import spinnerDefault from '../assets/Spinner default.png';
import spinner2 from '../assets/Spinner Variant 2.png';
import spinner3 from '../assets/Spinner Variant 3.png';
import spinner4 from '../assets/Spinner Variant 4.png';

export default function LoadingSpinner() {
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const images = [spinnerDefault, spinner2, spinner3, spinner4];
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex === 3 ? 0 : prevIndex + 1));
    }, 900);

    return () => clearInterval(interval);
  }, []);

  const images = [spinnerDefault, spinner2, spinner3, spinner4];

  return (
    <div>
      <motion.img
        key={images[imageIndex]}
        src={images[imageIndex]}
        alt="Loading spinner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      />
    </div>
  );
}

